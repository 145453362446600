import React from 'react';
import { Link } from 'gatsby';

import CxSearch from './cx-search';
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import { StaticImage } from 'gatsby-plugin-image';

const AmazonAws = () => (
	<main>
		<section className="cx_catagories">
			<div className="container-fluid cx_top">
				<div className="container">
					<div className="row">
						<div className="col-md-8 col-sm-10">
							<h2>
								Hire top freelance<br />
								<span> Amazon AWS Experts.</span>
							</h2>
							<p>
								Codestaff is a marketplace for top AMAZON AWS Experts. Top companies and start-ups
								choose Codestaff Amazon AWS freelancers for their mission critical software projects.
							</p>
							<Link to="/hire/" className="skills-banner-btn">
								Hire a top AMAZON AWS Expert now
								<StaticImage
									src="../../images/arrow.png"
									alt="arrow"
									className="img-fluid"
									style={{ margin: '0 1rem' }}
								/>
							</Link>
						</div>
					</div>
				</div>
			</div>
			<CxSearch
				title="HIRE FREELANCE AMAZON AWS EXPERTS"
				banner="/amazon-aws.png"
				bannerAlt="amazon aws banner"
			/>
		</section>
		<HireAndApply />
		<TopTrendingSkills />
	</main>
);
export default AmazonAws;
