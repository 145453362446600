import React from 'react'
import Layout from '../components/layout'
import AmazonAws from '../components/categories/amazon-aws'
import Seo from '../components/seo'

const AmazonAwsPage = ({ location }) => {
  return (
    <Layout location={location}>
      <AmazonAws />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Amazon Aws Freelancers | Codestaff'
  const description =
    'Hire the best Amazon Aws freelancers at Codestaff. Get the top 1% of Amazon Aws professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default AmazonAwsPage
